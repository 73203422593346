<template>
  <div>
    <ComHeader menuKey="plan"></ComHeader>
    <!-- banner -->
    <div class="common-banner">
      <div class="container ui acenter">
        <img class="banner-icon animate__animated animate__backInLeft" src="@/assets/images/plan-banner-icon.png" alt="">
        <div class="f1 animate__animated animate__backInDown">
          <p class="banner-title">解决方案</p>
          <p>中码科技为多个行业的各类政企客户提供了基于可信身份认证的创新智慧解决方案，持续不断为每个客户创造价值。</p>
        </div>
      </div>
    </div>
    <!-- main -->
    <div class="container plan-detail">
      <template v-for="item in planList" :key="item.title">
        <router-link class="plan-item hvr-float-shadow" :style="{visibility:item.title?'':'hidden'}" :to="'/plandetail?id='+item.id" >
          <img class="item-img" :src="item.imgUrl" alt="">
          <div class="item-title">{{item.title}}</div>
          <p class="item-txt">{{item.content}}</p>
        </router-link>
      </template>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

export default defineComponent({
  components: {ComHeader,ComFooter},

  setup() {
    const planList = [
      {id:"active",title:"重大活动",imgUrl:require("@/assets/images/plan/plan-img1.png"),content:"实现展览、演唱会、发布会、论坛等大型活动的快捷通行。参与人可提前预约，核验通行，有效避免通行阻塞，同时支持疫情防控联动，实时同步防疫异常信息，并对关注人员实时预警，警企联动、快速布防，为大家健康安全提供有力保障"},
      {id:"hospital",title:"医院校园",imgUrl:require("@/assets/images/plan/plan-img2.png"),content:"校园入口部署访客管理系统，建立校园白名单，实时核验出入人员身份，记录出入信息，可疑行踪一目了然。异常人员实时预警，核验信息自动上传公安内网，精准信息比对。确保校园安全"},
      {id:"agency",title:"智能代办",imgUrl:require("@/assets/images/plan/plan-img3.png"),content:"目前很多业务允许他人代办，为了规范代办流程，可以通过中码身份证有效期核验业务，业务受理方通过我司的前端感知设备读取身份证信息，并通过网络核验此身份证的有效性，核验结果在前端感知设备得屏幕显示，同时提供打印身份信息凭条"},
      {id:"special",title:"特种行业",imgUrl:require("@/assets/images/plan/plan-img4.png"),content:"对于特种行业，例如散装汽油销售、服务业、物流业、娱乐场所等，与人们生活息息相关，人员流动较大，容易产生危害社会公共安全的事情，对于这些行业的治安管理，更需要严格把控"},
      {id:"online",title:"网络监管",imgUrl:require("@/assets/images/plan/plan-img5.png"),content:"对于网络游戏、直播及互联网金融、社交电商等，引入身份核验系统，减少不良影响，维护网络安全，提升网络综合治理能力"},
      {id:"epidemic",title:"疫情联防",imgUrl:require("@/assets/images/plan/plan-img6.png"),content:"疫情防控，联防联控，对人员身份管理，通过身份证号反馈健康码信息，或是通过读取国康码信息，记录人员信息，对人员的核酸、疫苗、行程数据进行查验，保证疫情期间，各种场景下的人员管控核验"},
      {id:"protection",title:"智慧内保",imgUrl:require("@/assets/images/plan/plan-img7.png"),content:"以大数据、人工智能等前沿技术为依托，通过智能化安保体系，实现内保工作治安要素可防、可控、可查，推动内保工作方式由事后处置向事前预警转型升级，构建覆盖重点单位和行业系统的单位内部智慧化治安防控格局。可以应用于医院内保、学校内保、银行安全防控、党政机关及各企事业单位、涉密部门监管、单位保卫队伍监管等"},
      {id:"community",title:"智慧社区",imgUrl:require("@/assets/images/plan/plan-img8.png"),content:"应用人脸识别、可信身份核验等前沿技术，并结合一系列硬件设施，构建出一体化解决方案，加强对访客监控管理，做到主动预警和有效拦截，提升业主满意度及工作效率，，同时也能支持第三方ERP、访客系统的直接对接，实现社区的智慧化管理，让居民生活更加安全、便捷"},
      {id:"building",title:"智慧楼宇",imgUrl:require("@/assets/images/plan/plan-img9.png"),content:"针对写字楼、企业园区、大型卖场、图书馆等特定公共场所，应用可信身份核验等前沿技术，并结合智能摄像机、门禁等硬件设施，部署身份信息核验系统，为客户单位提供日常访客身份核查，受限人员匹配报警、联动报警、嫌疑人/高危人员进入时，实时发送预警信息及智能风险评估实现此类公共场所的智慧安防及智能管理"},
      {id:"hotel",title:"智慧酒店",imgUrl:require("@/assets/images/plan/plan-img10.png"),content:"针对酒店、民宿等住宿经营场所，应用可信身份核验等前沿技术，并结合身份核验设备和应用软件，实现智慧安防和快捷入住，规范整个入住管理流程，有效保障经营者和客人的人身财产安全，又能节省人力资源，并避免入住高峰期客人等待时间过久，体验差的问题"},
      {id:"traffic",title:"智慧交通",imgUrl:require("@/assets/images/plan/plan-img11.png"),content:"应用可信身份核验和自助身份认证等技术，高效保障大中型城市公共交通枢纽的安全，并为旅客提供便捷的身份自证服务，让公共交通拥有更好的体验"},
      {id:"insurance",title:"金融保险",imgUrl:require("@/assets/images/plan/plan-img12.png"),content:"应用于银行、金融、保险等行业，通过可信身份核验技术和智能硬件，严格核查人员身份信息，打击各种金融违法犯罪行为和反电信网络诈骗，维护社会健康发展"},


      /*{id:"building",title:"智慧楼宇",imgUrl:require("@/assets/images/plan-img2.png"),content:"基于可信身份认证、电子取证、黑名单的对比为核心能力，打造集“预约来访、访客登记、无证通行、身份核验、测温检测“等多种应用场景于一体的全楼宇智慧“无证通“方案。"},
      {id:"epidemic",title:"智慧园区",imgUrl:require("@/assets/images/plan-img6.png"),content:"基于以可信身份认证、关注人员比对为核心能力，打造集“预约来访、访客登记、无证通行、身份核验、测温检测”等多种应用场景于一体的全园区智慧“无证通”方案。"},
      {id:"hotel",title:"智慧酒店",imgUrl:require("@/assets/images/plan-img1.png"),content:"依托领先的可信身份认证技术及关注人员核查能力优势，以住客为核心，覆盖入住登记、 无证核验、身份识别、员工管理等功能于一体，具备：1.无证数字登记，快速高效，避免拥堵等待；2.安全要求高，防止照片、视频等攻击；3.高实时性，及时返回人脸对比结果；4.重点关注人员比对短信预警，保障酒店安全等特点"},
      {id:"park",title:"大型场馆",imgUrl:require("@/assets/images/plan-img3.png"),content:"针对举办展会等大型活动场所，实我风险核验系统，可以实现提前预约，核验通过，有效避免人员通行阻塞。关注人员实时预警，同步属地公安系统，快速高效，做到提前预防，警企联动，快速布防，支持疫情防控联动，实时同步防疫异常信息，整体项目建设快，成本低，场景覆盖广，设备便携。"},
      {id:"hospital",title:"平安医院",imgUrl:require("@/assets/images/plan-img5.png"),content:"针对医院这种人员流动复杂的场景，可以进行特定关注人员设置，实时预警医闹人员等特殊群体，信息实时同步公安内网系统，有效保证公共场所安全，提前预约，核验通行，有效解决就医高峰期人员拥挤问题，同时与疫情防控信息联动，实时同步防疫异常信息。"},
      {id:"traffic",title:"智慧交通",imgUrl:require("@/assets/images/plan-img4.png"),content:"解决人员出行乘坐交通工具时，忘记携带必要证件的问题，通过实人信息比对技术服务的接口能力，对用户的两项及人像信息进行合一性验证，实现无证核验，从根本上解决未带证件的出行难问题。实现无证通行，无证购票。"},
      */
    ]

    return {
      planList
    };
  },

});
</script>

<style lang="less" scoped>
.common-banner{
  background: url("../../assets/images/common-banner-bg.png") no-repeat;
  background-size: 100% 100%;
  .banner-icon{
    width: 242px;
    height: 185px;
    margin-right: 50px;
  }
}
.plan-detail{
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .plan-item{
    margin-bottom: 80px;
    width: 30%;
    border: 1px solid rgba(180, 180, 180, 0.45);
    border-radius: 8px;
    &:hover{
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    }
    .item-img{
      width: 100%;
      height: 155px;
    }
    .item-title{
      font-weight: 600;
      font-size: 20px;
      color: #142D70;
      text-align: center;
      margin: 10px 0;
    }
    .item-txt{
      padding: 0 13px;
      color: #8493B7;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>

<style lang="less" scoped>
.mobile{
  .common-banner{
    height: 300px;
    padding:50px 20px 0;
    display: flex;
    align-items: center;
    .banner-icon{
      width: 121px;
      height: auto;
      margin-right: 50px;
    }
  }
  .plan-detail{
    padding: 20px 10px;
    .plan-item{
      width: 48%;
      margin-bottom:10px;
      font-size: 13px;
      .item-title{
        font-size: 16px;
        margin-bottom: 5px;
      }
      .item-img{
        height:auto
      }
    }
  }
}
</style>